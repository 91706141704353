.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  font-family: "Roboto", sans-serif;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.disable {
  opacity: 0.5;
  /* cursor: not-allowed; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  font-family: "Roboto", sans-serif;
}

.active :hover {
  /* background-color: #4d6c80; */
  border-radius: 10px;
  color: black;
}

.active1 {
  background-color: #4d6c80;
  border-radius: 10px;
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.active2 {
  background-color: #4d6c80;
  border-radius: 10px;
  color: #131313;
  font-size: 0.9rem;
  font-weight: bold;
}

.addbtn {
  background-color: #c5f6e2;
  border: none;
  color: black;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
}

.cancelbtn {
  background-color: #ffe7e8;
  border: none;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 100;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.bt {
  background-color: #dddcdd;
  color: #131313;

  font-weight: bold;
  font-size: 20px;

  border-radius: 5px;
  --bs-accordion-active-bg: #e7e8eb;
  --bs-accordion-btn: #edabed;
  --bs-accordion-btn-focus-border-color: #e7e8eb;
  --bs-accordion-btn-focus-box-: #dddcdd;
}

/* .addbtn {
  background-color: #C5F6E2;
  color: black;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #223947;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #223947;
}

/* .{
  background-color: rgb(246, 244, 244);
  
} */
.button {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 15px;
  background-color: red;
  border-radius: 10px;
  border: none;
  color: white;
  position: relative;
  cursor: pointer;
  font-weight: 900;
  transition-duration: 0.2s;
  background: "white";
  background: linear-gradient(0deg, #000, #272727);
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 10px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

.marginright {
  margin-right: 10px;
  margin-left: 5px;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button:after {
  filter: blur(50px);
}

.wrapper {
  padding: 4em;
  padding-bottom: 0;
}

.currency-selector {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem;
  border: 0;
  background: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") 90%/12px 6px no-repeat;

  font-family: inherit;
  color: inherit;
}

.currency-amount {
  text-align: right;
}

.currency-addon {
  width: 6em;
  text-align: left;
  position: relative;
}

.my-multi-lines-item {
  font-size: 14px;
  white-space: initial;
  display: flex;
  height: auto;
  line-height: normal;
  padding: 10px;
}

.btnPlus {
  background-color: #88d5ff;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.btn- {
  background-color: #ff9688;
  padding: 6px 13px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.my-multi-lines-text {
  margin-top: auto;
  margin-bottom: auto;
}

.doc-target {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #000;
  line-height: 1.6em;
  margin: 0 auto;
}

.outer {
  /* padding: 72pt 72pt 72pt 72pt; */
  border: 1px solid #000;
  margin: 0 auto;
  width: 550px;
}

.image {
  background-image: url("./Images/colinaMS10.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.ambulanceImg {
  background-image: url("./Images/ambulance.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
}

.imageland {
  background-image: url("./Images/landinfnew.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100vh;
}

.mainImage {
  background-image: url("./Images/Login.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 50%;
  height: 100vh; */
}

.imageland1 {
  background-image: url("./Images/landinfnew.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.steth {
  background-image: url("./Images/steth.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.steth2 {
  background-image: url("./Images/steth.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/* .welcome {
  background-image: url("./Images/welcome1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;



} */

.hand1 {
  background-image: url("./Images/hand1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hand2 {
  background-image: url("./Images/hand2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hand3 {
  background-image: url("./Images/hand6.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hand4 {
  background-image: url("./Images/hand4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: 1px solid #d7d0d0;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: justify;
  padding: 8px;
  background-color: #fff;
  color: #000;
}

th {
  background-color: #fff;
  color: #000;
}

.chatbot-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #88d5ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* cahtbot */
.chat-container {
  background-color: white;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 500px;
  position: relative;
  overflow: scroll;
}

.maincontnt {

  position: absolute;
  margin-bottom: 500px;
  width: auto;
  margin-right: 500px;
  padding: 0px 0px;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  /* Adjust as needed */
  word-wrap: break-word;
}

.bot-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #f0f0f0;
  color: #333;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 8px;
}

.user-message {
  width: auto;
  border: 1px solid #ccc;
  word-wrap: break-word;
  background-color: #b9e1fa;
  color: #090909;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.message-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.message-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.message-input button {
  padding: 8px 12px;
  border: none;
  background-color: #f0f2f3;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-: inset 0 0 5px white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b9e1fa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9e1fa;
}

.bg {
  background-color: #b9e1fa;
}

.myDIV:hover+.hide {
  display: block;
}

.hide {
  display: none;
}

.hide:hover {
  display: block;
}

.toast-position {
  top: 8rem !important;
}

.language-buttons {
  height: 30px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: start;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.star {
  font-size: 24px;
  cursor: pointer;
}

.filled {
  color: #f39701;
}

.yellow {
  color: yellow;
}

.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 22px;
  box-shadow: 0 0 2px #0074d9;
  width: 100%;
}

/* addbtnfunctionality start */
.addfc:hover {
  background-color: #c5f6e2;
  /* border: solid 2px #b6dcf9; */
  color: #000;
  border-radius: 5px;
}

.addfc {
  cursor: pointer;
}

/* addbtnfunctionality end */

.appointment .text {
  font-size: 15px;
  padding: 15px 10px 15px 10px;
}

@media (max-width: 576px) {
  .fontSigeDate {
    font-size: 14px;
  }

  .medicationvital {
    flex-direction: column;
    gap: 5px;
  }

  .welcomeHide {
    display: none;
  }

  .welcome1 {
    background-image: url("./Images/welcome1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* opacity: 0.8; */
    /* border:"5px solid purple"; */
    /* border-radius: 50px; */
  }

  .ImageContainer {
    display: none;
  }

  .loginCard {
    width: 90vw;
  }

  .patientdetails {
    display: none;
  }

  .MobilePatient {
    display: block;
  }

  .ProfileQr {
    width: 100%;
  }

  .paymetDate {
    flex-direction: column;
  }

  .dateMobile {
    flex-direction: row;
  }

  .dateMargin {
    margin-left: 10px;
  }

  .PatientDetails {
    flex-direction: column;
  }

  .AddAppointment {
    flex-direction: column;
  }

  .addAppointment {
    flex-direction: column;
  }

  .addButton {
    margin-top: 10px;
  }

  .generateMobile {
    flex-direction: column;
  }

  .tdBefore {
    font-size: 16px;
  }

  .pivoted {
    font-size: 16px;
  }

  .mobileIcon {
    min-width: 110px;
  }

  .BookAppointment {
    font-size: 16px;
    /* font-weight: bold; */
  }

  .mainrow {
    flex-direction: column;
  }

  .col-789 {
    width: 100%;
  }

  .Chart {
    display: flex;
  }

  .todayAppointment {
    flex-direction: column;
    max-height: 150px;
  }

  .borderBottom {
    border-bottom: solid 1px #e2dddd;
  }

  .dashboardCards {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .textSize {
    font-size: 15px;
  }

  .appointment .text {
    font-size: 15px;
    padding: 15px 10px 15px 10px;
  }

  .sideHeader {
    font-size: 17px;
  }

  .labPackages {
    width: 100%;
  }

  .labtest1025 {
    display: none;
  }

  .col-425 {
    width: 100%;
  }

  .editAppointmentImage {
    height: 15vh;
    width: 15vh;
    border-radius: 40vh;
  }

  .serviceCard {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .margintop {
    margin-top: 0px;
  }

  .Arrow {
    display: block;
  }

  .medicationBottom {
    margin-bottom: 10px;
  }

  .marginMain {
    margin: 5px 5px;
  }

  .medicationGap {
    gap: 10px;
  }

  .patientlongitude {
    flex-direction: column;
    gap: 10px;
  }

  .chatPadding {
    padding: 9px;
  }

  .letterBorder {
    border-bottom: 1px solid #e3e3e3;
  }

  .letterMargin {
    margin-top: 10px;
  }

  .letterHeight {
    display: none;
  }

  .letterWaist {
    display: none;
  }

  .letterHip {
    display: none;
  }

  .letterBmi {
    display: none;
  }

  .letterWeight {
    display: none;
  }

  .bodyMobile {
    display: block;
  }

  .bodyLarge {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .fontSigeDate {
    font-size: 14px;
  }

  .medicationvital {
    flex-direction: column;
    gap: 5px;
  }

  .bodyLarge {
    display: block;
  }

  .bodyMobile {
    display: none;
  }

  /* .welcome{
    height: 60vh;
    width: 75vw;
  } */
  .addButton {
    margin-top: 10px;
  }

  .chatPadding {
    padding: 10px;
  }

  .loginCard {
    width: 40vw;
  }

  .margintop {
    margin-top: 2px;
  }

  .Arrow {
    display: block;
  }

  .mobileIcon {
    min-width: 140px;
  }

  .MobilePatient {
    display: none;
  }

  .PatientDetails {
    /* display: flex; */
    flex-wrap: wrap;
  }

  .AddAppointment {
    /*for AddAppointment */
    flex-direction: row;
    flex-wrap: wrap;
  }

  .addAppointment {
    /*for BookAppointment */
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1;
  }

  .BookAppointment {
    font-size: 20px;
    /* font-weight: bold; */
  }

  .col-789 {
    width: 100%;
  }

  .col-service {
    width: 50%;
  }

  .mainrow {
    flex-direction: column;
  }

  .Chart {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ImageContainer {
    width: 50vw;
  }

  .todayAppointment {
    display: flex;
    max-height: 100px;
    align-items: center;
    justify-content: space-between;
  }

  .borderBottom {
    border-bottom: none;
  }

  .mobileCard {
    display: block;
  }

  .LaptopCard {
    display: none;
  }

  .dashboardCards {
    height: 125px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .textSize {
    font-size: 15px;
  }

  .appointment .text {
    font-size: 16px;
    padding: 15px 10px 15px 10px;
  }

  /*All Appointments header */
  .sideHeader {
    font-size: 25px;
  }

  .labPackages {
    width: 100%;
  }

  .labtest1025 {
    display: none;
  }

  .editAppointment {
    max-height: 1000px;
  }

  .editAppointmentImage {
    height: 20vh;
    width: 20vh;
    border-radius: 40vh;
  }

  .medicationBottom {
    margin-bottom: 10px;
  }

  .marginMain {
    margin: 10px 30px;
  }

  .medicationGap {
    gap: 10px;
  }

  .patientlongitude {
    flex-direction: row;
    gap: "10px";
  }

  .letterBorder {
    border-bottom: 1px solid #e3e3e3;
  }

  .letterHeight {
    position: relative;
    bottom: 480px;
    right: 260px;
  }

  .letterWaist {
    position: relative;
    bottom: 380px;
  }

  .letterHip {
    position: relative;
    bottom: 350px;
    right: 310px;
  }

  .letterBmi {
    position: relative;
    bottom: 230px;
    right: 280px;
  }

  .letterWeight {
    position: relative;
    bottom: 200px;
    right: 35px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .fontSigeDate {
    font-size: 14px;
  }

  .medicationBottom {
    margin-bottom: 10px;
  }

  .bodyMobile {
    display: none;
  }

  .bodyLarge {
    display: block;
  }

  .marginLeftUsedMedicine {
    margin-left: 0px;
  }

  .chatPadding {
    padding: 20px;
  }

  .minHeightforPwa {
    min-height: 75vh;
  }

  .loginCard {
    width: 40vw;
  }

  .margintop {
    margin-top: 8px;
  }

  .mobileIcon {
    min-width: 140px;
  }

  .Arrow {
    display: block;
  }

  .MobilePatient {
    display: none;
  }

  .PatientDetails {
    display: flex;
    flex-wrap: wrap;
  }

  .AddAppointment {
    /*for AddAppointment */
    display: flex;
    flex-wrap: wrap;
  }

  .addAppointment {
    /*for BookAppointment */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .BookAppointment {
    font-size: 20px;
  }

  .col-789 {
    width: 100%;
  }

  .col-service {
    width: 100%;
  }

  .mainrow {
    flex-direction: column;
    gap: 1px;
  }

  .Chart {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ImageContainer {
    width: 50vw;
  }

  .todayAppointment {
    display: flex;
    max-height: 100px;
    align-items: center;
  }

  .borderBottom {
    border-bottom: none;
  }

  .mobileCard {
    display: block;
  }

  .LaptopCard {
    display: none;
  }

  .dashboardCards {
    height: 125px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .textSize {
    font-size: 15px;
  }

  .appointment .text {
    font-size: 16px;
    padding: 15px 10px 15px 10px;
  }

  /*All Appointments header */
  .sideHeader {
    font-size: 25px;
  }

  .labPackages {
    width: 100%;
  }

  .labtest1025 {
    display: none;
  }

  .editAppointment {
    max-height: 1000px;
  }

  .editAppointmentImage {
    height: 15vh;
    width: 15vh;
    border-radius: 40vh;
  }

  .marginMain {
    margin: 10px 30px;
  }

  .medicationGap {
    gap: 10px;
  }

  .patientlongitude {
    display: flex;
    gap: 1;
  }

  .letterBorder {
    border-right: 1px solid #e3e3e3;
  }

  .letterHeight {
    position: relative;
    bottom: 480px;
    right: 260px;
  }

  .letterWaist {
    position: relative;
    bottom: 380px;
  }

  .letterHip {
    position: relative;
    bottom: 350px;
    right: 310px;
  }

  .letterBmi {
    position: relative;
    bottom: 230px;
    right: 280px;
  }

  .letterWeight {
    position: relative;
    bottom: 200px;
    right: 35px;
  }
}

@media (min-width: 979px) and (max-width: 1024px) {
  .fontSigeDate {
    font-size: 14px;
  }

  .medicationvital {
    flex-direction: column;
    gap: 5px;
  }

  .bodyMobile {
    display: none;
  }

  .bodyLarge {
    display: block;
  }

  /* .welcome{
    height: 60vh;
    width: 40vw;
  } */

  .marginLeftUsedMedicine {
    margin-left: 0px;
  }

  .chatPadding {
    padding: 30px;
  }

  .minHeightforPwa {
    min-height: 75vh;
  }

  .medicationBottom {
    margin-bottom: 10px;
  }

  .loginCard {
    width: 35vw;
  }

  .margintop {
    margin-top: 8px;
  }

  .Arrow {
    display: none;
  }

  .BookAppointment {
    font-size: 20px;
  }

  .ImageContainer {
    width: 50vw;
  }

  .Qrcode {
    height: 200;
  }

  .MobilePatient {
    display: none;
  }

  .PatientDetails {
    display: flex;
    flex-wrap: wrap;
  }

  .AddAppointment {
    /*for addAppointment */
    display: flex;
    flex-wrap: wrap;
  }

  .addAppointment {
    /*for BookAppointment */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .col-1023 {
    width: 50%;
  }

  .mainrow {
    display: flex;
    justify-content: space-between;
  }

  .Chart {
    display: flex;
    justify-content: center;
  }

  .todayAppointment {
    flex-direction: column;
    /* justify-content: space-between; */
    max-height: 150px;
  }

  .col-1025 {
    width: 100%;
  }

  .borderBottom {
    border-bottom: solid 1px #eeee;
  }

  .dashboardCards {
    height: 125px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .textSize {
    font-size: 14px;
  }

  .appointment .text {
    font-size: 16px;
    padding: 15px 10px 15px 10px;
  }

  .sideHeader {
    font-size: 25px;
  }

  .labPackages {
    width: 100%;
  }

  .labtest1025 {
    display: none;
  }

  .editAppointment {
    max-height: 1000px;
  }

  .editAppointmentImage {
    height: 20vh;
    width: 20vh;
    border-radius: 40vh;
  }

  .marginMain {
    margin-left: 10px;
    margin-right: 2px;
  }

  .previousAppointmentsPadding {
    padding-left: 5px;
    padding-right: 0px;
  }

  .medicationGap {
    gap: 0px;
  }

  .patientlongitude {
    display: flex;
    gap: 1;
  }

  .letterBorder {
    border-right: 1px solid #e3e3e3;
  }

  .letterHeight {
    position: relative;
    bottom: 480px;
    right: 260px;
  }

  .letterWaist {
    position: relative;
    bottom: 380px;
  }

  .letterHip {
    position: relative;
    bottom: 350px;
    right: 310px;
  }

  .letterBmi {
    position: relative;
    bottom: 230px;
    right: 280px;
  }

  .letterWeight {
    position: relative;
    bottom: 200px;
    right: 35px;
  }
}


@media (min-width: 1025px) {
  .medicationGap {
    gap: 10px;
  }

  .fontSigeDate {
    font-size: 12px;
  }

  .bodyLarge {
    display: block;
  }

  .bodyMobile {
    display: none;
  }

  .marginLeftUsedMedicine {
    margin-left: 0px;
  }

  .chatPadding {
    padding: 30px 50px;
  }

  .minHeightforPwa {
    min-height: 75vh;
  }

  .patientlongitude {
    display: flex;
    gap: 1;
  }

  .marginMain {
    margin: 10px 20px;
  }

  .medicationvital {
    display: flex;
    gap: 5px;
  }

  .loginCard {
    width: 27vw;
  }

  .Arrow {
    display: none;
  }

  .margintop {
    margin-top: 8px;
  }

  .BookAppointment {
    font-size: 20px;
    /* font-weight: bold; */
  }

  .ImageContainer {
    width: 50vw;
  }

  .MobilePatient {
    display: none;
  }

  .PatientDetails {
    display: flex;
    flex-wrap: wrap;
  }

  .AddAppointment {
    /*for AddAppointment */
    display: flex;
    flex-wrap: wrap;
    /* gap: 4px; */
  }

  .addAppointment {
    /*for BookAppointment */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .mainrow {
    display: flex;
    justify-content: space-between;
  }

  .col-1024 {
    width: 33%;
  }

  .Chart {
    display: flex;
    justify-content: center;
  }

  .todayAppointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 100px;
  }

  .borderBottom {
    border-bottom: none;
  }

  .dashboardCards {
    height: 125px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .textSize {
    font-size: 15px;
  }

  .appointment .text {
    font-size: 16px;
    padding: 15px 10px 15px 10px;
  }

  .sideHeader {
    font-size: 25px;
  }

  .labPackages {
    width: 50%;
  }

  .packageContainer {
    display: flex;
  }

  .labtest1024 {
    display: none;
  }

  .editAppointment {
    max-height: 600px;
  }

  .editAppointmentImage {
    height: 20vh;
    width: 20vh;
    border-radius: 40vh;
  }

  .letterBorder {
    border-right: 1px solid #e3e3e3;
  }

  .letterHeight {
    position: relative;
    bottom: 480px;
    right: 260px;
  }

  .letterWaist {
    position: relative;
    bottom: 380px;
  }

  .letterHip {
    position: relative;
    bottom: 350px;
    right: 310px;
  }

  .letterBmi {
    position: relative;
    bottom: 230px;
    right: 305px;
  }

  .letterWeight {
    position: relative;
    bottom: 200px;
    right: 35px;
  }
}

@media (min-width: 768px) {
  .dateMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 35px;
  }
}

.mobilepagination {
  display: none;
}

.pointer {
  cursor: pointer;
}

.main-content {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 82px);
}

.content-section {
  width: 100%;
}

.leftMenuTogggle {
  position: absolute;
  top: 12px;
  left: 50px;
  color: #fff;
}

a.ps-menu-button:hover {
  color: #000;
}

.ps-menuitem-root.active {
  background-color: #f7f7f7;
}

.ps-menuitem-root.active a {
  color: #000;
}

@media (min-height: 768) {
  .sidenavs {
    max-height: 100vh;
    overflow-y: scroll;
    width: "auto";
  }
}

@media (max-height: 768) {
  .sidenavs {
    max-height: 100vh;
    overflow-y: scroll;
    width: "auto";
  }
}

.navbar-expanded {
  width: 150px;
}

.navbar-collapsed {
  width: 150px;
}

.sidebar {
  transition: width 0.3s ease-in-out;
}

.pro-sidebar {
  width: 250px;
  /* Change this value to your desired width */
}

.textWrap {
  text-wrap: nowrap;
}

.bgHover {
  background-color: #abbbc6;
}

.container {
  text-align: center;
  margin-top: 20px;
}

.dropdown-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 50px;
  background-color: #f1f1f1;
  margin-right: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.dropdown-menu a:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .addappointweb {
    display: none;
  }

  .addappointmentmobile {
    display: block;
  }
}

@media (max-width: 768) {
  .tableresponsive {
    max-width: 50px;
  }
}

@media (min-width: 769px) {
  .tableresponsive {
    max-width: 100px;
  }
}

@media (min-width: 769px) {
  .addappointweb {
    display: block;
  }

  .addappointmentmobile {
    display: none;
  }

  .mobileIcon {
    min-width: 90px;
  }
}

.signature15vh {
  height: 15vh;
}

.gm-style-iw-chr {
  margin: -9px !important;
}

.css-wv3bts>.ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 0px;
  color: #000;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-4 {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.fw-bold.underline {
  text-decoration: underline;
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: none !important;
    border-bottom: 1px solid #eee !important;
    padding: 0.25em;
  }
}

.dateBorder {
  border-bottom: 1px solid #efefef;
}

.ep-arrow.p-5 {
  padding: 0 !important;
}

.rmdp-container {
  width: 100%;
}

.close-page-content {
  display: none !important;
}

.previousAppointments {
  font-weight: 500;
  font-size: 18px;
  align-items: center;
}

.borderBottomPatient {
  border-bottom: solid 1px #eeee;
  margin-right: 10px;
  margin-left: 10px;
}

.dotterLine {
  border-bottom: 0.5rem dotted #d8efff;
}

.card-container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px 0px;
}

@media (max-width: 576px) {
  .heightUsedMed {
    min-height: 35vh;
  }

  .updateAppointment {
    flex-direction: column;
  }

  .history {
    display: flex;
    justify-content: end;
    margin: 2px;
    gap: 5px;
  }

  .welcomepadding {
    /* padding: 25px; */
    margin-right: 20px;
    margin-left: 20px;
    border: 2px solid #c1bfbf;
  }

  .welcome {
    margin-top: 10px;
    height: 60vh;
    width: 80vw;
    border-radius: 50px;
    border: 5px solid #d8efff;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .welcomepadding {
    padding: 25px;
    margin-right: 30px;
    margin-left: 30px;
    border: 2px solid #c1bfbf;
  }

  .heightUsedMed {
    min-height: 35vh;
  }

  .welcome {
    margin-top: 10px;
    height: 60vh;
    width: 80vw;
    border-radius: 50px;
    border: 5px solid #d8efff;
  }

  .updateAppointment {
    flex-direction: column;
  }

  .history {
    display: flex;
    justify-content: end;
    gap: 5px;
    margin: 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .heightUsedMed {
    min-height: 35vh;
  }

  .welcomepadding {
    padding: 25px;
    margin-right: 30px;
    margin-left: 30px;
    border: 2px solid #c1bfbf;
  }

  .updateAppointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome {
    height: 60vh;
    width: 85vw;
    border-radius: 50px;
    border: 5px solid #d8efff;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .heightUsedMed {
    min-height: 66vh;
  }

  .welcomepadding {
    padding: 60px 25px;
    margin-right: 30px;
    margin-left: 30px;
    border: 2px solid #c1bfbf;
  }

  .updateAppointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome {
    height: 70vh;
    width: 40vw;
    border-radius: 50px;
    border: 5px solid #d8efff;
  }
}

@media (min-width: 1200px) {
  .heightUsedMed {
    min-height: 35vh;
  }

  .welcomepadding {
    padding: 60px 25px;
    margin: 20px 40px;
    border: 2px solid #c1bfbf;
  }

  .updateAppointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .welcome {
    height: 70vh;
    width: 45vw;
    border-radius: 50px;
    /* border:"5px solid #d8EFFF" */
    border: 5px solid #d8efff;
  }
}

.bodystructure {
  background-image: url("./Images/Body-PNG.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  width: 50vh;
  height: 80vh;
}

.body-image-container {
  position: relative;
  text-align: center;
}

.input-field label {
  font-weight: bold;
  margin-left: 300px;
  /* Add margin between the label and the line */
}

.input-field input {
  width: 90px;
  /* Adjust the width as needed */
}

.tableBorder {
  border: 2px solid rgb(122, 122, 122);
}

.leaflet-container {
  height: 426px;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
  height: 36px !important;
  width: 36px !important;
}

.main {
  margin: 5px;
  padding: 5px;
  max-height: 90vh;
  overflow: auto;
}

.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
}

.pain-scale {
  display: flex;
  justify-content: space-between;
}

.pain-level {
  flex: 1;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pain-level:hover {
  opacity: 0.8;
}

.selected {
  border: 2px solid black;
}

.headink {
  color: #000;

  text-align: start;

  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.radioText {
  color: #7F7F7F
}

.language-buttons {
  height: 30px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: start;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.autocomplete-dropdown {
  position: relative;
}

.autocomplete-dropdown input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.autocomplete-dropdown .suggestions {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 100%;
  z-index: 1;
}

.autocomplete-dropdown .suggestions li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-dropdown .suggestions li:hover {
  background-color: #f0f0f0;
}

.imagecolor {
  filter: hue-rotate(90deg);


}

.autocomplete-dropdown {
  position: relative;
}

.autocomplete-dropdown input {
  width: 100%;
  padding-right: 30px;
  /* Adjust paddingRight for the icon width */
}

.cancel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;

  cursor: pointer;
}

.suggestions {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f5f5f5;
}

@media(max-width:1200px) {
  .marginLG {
    margin-top: 4px;
  }

}


.calendar_default_corner [unselectable="on"]:nth-child(2),
div[style*="background-color: rgb(255, 102, 0)"] {
  display: none !important;
}

.custom-datepicker-input {
  border-radius: 14px;
}

.wrapper1 {


  animation: img-top 0.8s linear;
}

@keyframes slideInTopToBottom {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

a.tooltip #hover {
  display: none;
}

a.tooltip:hover #hover {
  border: #666 2px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: #e3e3e3;
  left: 0px;
  margin: 15px;
  width: 300px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

@media(max-width:1200px) {
  .buttonSize {
    font-size: 14px;
  }
}

@media(min-width:1201px) {
  .buttonSize {
    font-size: 16px;
  }
}

.zindex {
  z-index: 100;
}

.dir {
  direction: ltr;
  /* text-align: end; */
}

.end {
  text-align: end;
}

.register:hover {
  color: #3931cb;

}

@media (min-width:"1024px") {
  .vinay22 {
    background-color: #00ff00;
  }
}

@media (max-width:"1024px") {
  .vinay22 {
    background-color: #364c94;
  }
}

.gm-style-iw>button {
  display: none !important;
}

.checkboxWithText {
  display: flex;
  align-items: center;
}

.checkboxWithText label {
  color: #000;
  font-weight: medium;
  margin-left: 0.5em;
  margin-right: 8px;
}

.checkboxWithText input[type="radio"]:checked+label {
  color: #000;
  /* Change this to the desired color */
  font-weight: bold;
}